import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link } from "gatsby"
import Lightbox from "../components/Lightbox";

import "slick-carousel/slick/slick-theme.css";
import "../assets/css/slick.css";

let setVideoTimeout;

export default function Carrousel({pageType, isVisibleMore}) {
  console.log(isVisibleMore)
  const [data, setData] = useState([]);

  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const [open, setOpen] = useState(false);
  const [isHover, setIsHover] = useState([false, false, false, false]);

  const [currentItem, setCurrentItem] = useState(null); // Lightbox

  const [loadedVideo, loadVideo] = useState(null);

  const [heightVideo, setHeightVideo] = useState(250);
  const videoBox = useRef(null);

  const handleMouseEnter = (rowNo) => {
    if (rowNo === 0) {
      setIsHover([true, false, false, false]);
    } else if (rowNo === 1) {
      setIsHover([false, true, false, false]);
    } else if (rowNo === 2) {
      setIsHover([false, false, true, false]);
    } else if (rowNo === 3) {
      setIsHover([false, false, false, true]);
    }
 };
 
 const handleMouseLeave = (rowNo, event) => {
    if (event.target.className === "slider-vid") {
      setIsHover([false, false, false, false]);
    } else {
      if (rowNo === 0) {
        setIsHover([false, true, true, true]);
      } else if (rowNo === 1) {
        setIsHover([true, false, true, true]);
      } else if (rowNo === 2) {
        setIsHover([true, true, false, true]);
      } else if (rowNo === 3) {
        setIsHover([true, true, true, false]);
      }
    }
 };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search);
      const videoId = params.get("video");

      if (videoId) {
        const foundMovie = data.find((movie) => movie._id === videoId);
        setCurrentItem(foundMovie);
        setOpen(true);
      }
    }
  }, [])


  useEffect(() => {
    axios
      .get(`https://api.whitebrim.co/api/model/6351989605e277b6eaf1a05b/${pageType}`)
      .then(response => response.data)
      .then(data => {
        data.results = data.results.sort((a, b) => a.ordering.global - b.ordering.global);

        data.results.forEach(leResult => {
          if (leResult.thumbnail && leResult.thumbnail.edition_options && leResult.thumbnail.edition_options.crop_options) {
            let myEditOption = leResult.thumbnail.edition_options;
            let post_edition_string = "&rect=" + Math.floor(myEditOption.crop_options.x) + "," + Math.floor(myEditOption.crop_options.y) + "," + Math.floor(myEditOption.crop_options.width) + "," + Math.floor(myEditOption.crop_options.height) + "&fit=crop";
            leResult.thumbnail.url = leResult.thumbnail.url.trim() + "?fm=jpg&w=854&h=480" + post_edition_string;
          } else if (leResult.thumbnail && leResult.thumbnail.url) {
            leResult.thumbnail.url = leResult.thumbnail.url.trim() + "?fm=jpg&w=854&h=480";
          } else {
            return null;
          }
        })

        function distributeIntoBlocks(array) {
          const breakLimit = 6;
          if (array.length < breakLimit) {
            return array;
          }
          
          let result = [];
          for (let i = 0; i < array.length; i += breakLimit) {
            let block = array.slice(i, i + breakLimit);
            while (block.length < breakLimit && i + block.length < array.length) {
              block.push(array[i + block.length]);
            }
            result.push(block);
          }
          if (result[result.length - 1].length < breakLimit ) {
            result[result.length - 2].push(...result[result.length - 1]);
            result.pop();
          }
          return result;
        }
        const aux = distributeIntoBlocks(data.results);
        
        if (!isVisibleMore) aux.length = 3;
        setData(aux)
      });
  }, [isVisibleMore]);

  const setModal = (status) => {
    setIsHover([false, false, false])
    setOpen(status)
  }

  return (
      <div>
        {
          data && data.map((sliderNo, index) => (
          <div className="wrapper" style={{ height: heightVideo }}
            onMouseEnter = {(ev) => handleMouseEnter(index)}
            onMouseLeave = {(ev) => handleMouseLeave(index, ev)}
          >
            <div className="marquee" style={{animation: `${index % 2 !== 0 ? "marquee-neg": "marquee"}  70s ${isHover[index] ? 'paused' : 'running'} linear infinite`, height: heightVideo}}>
              {sliderNo && sliderNo.concat(sliderNo).map((movie, round) => (
              <div className="sick-slide"
                  key={`${movie._id}_${round}`}>
                  <img
                    ref={videoBox}
                    style={{
                      top: '0%',
                      display: 'block',
                      position: 'absolute'
                    }}
                    className={`slider-img`}
                    src={movie.thumbnail?.url}
                    alt="firstvideoframe"
                    onClick={() => {
                      if (window.matchMedia("(max-width: 768px)").matches) {
                        //* If mobile you can only open the modal if the video is playing
                        if (loadedVideo && loadVideo === `${movie._id}_${round}`) {
                          window.history.pushState({}, "", `${url}?video=${movie._id}`)
                          setOpen(true);
                          setCurrentItem(movie);
                        } else if(!movie.videofile) {
                          window.history.pushState({}, "", `${url}?video=${movie._id}`)
                          setOpen(true);
                          setCurrentItem(movie);
                        }
                      } else {
                        window.history.pushState({}, "", `${url}?video=${movie._id}`)
                        setOpen(true);
                        setCurrentItem(movie);
                      }             
                    }}
                    onMouseEnter = { event => {
                      if (window.matchMedia("(max-width: 768px)").matches) {
                        //* If mobile and no video is playing play the current one
                        if (!loadedVideo) {
                          setVideoTimeout = setTimeout(() => {
                            if (movie.videofile) loadVideo(`${movie._id}_${round}`)
                          }, 500);
                        } else {
                          //* Else clear the last one and play the current one
                          loadVideo(null);
                          clearTimeout(setVideoTimeout);
                          setVideoTimeout = setTimeout(() => {
                            if (movie.videofile) loadVideo(`${movie._id}_${round}`)
                          }, 500);
                        }
                      } else {
                        setVideoTimeout = setTimeout(() => {
                          if (movie.videofile) loadVideo(`${movie._id}_${round}`)
                        }, 500);
                      }
                    }}
                  />
                
                {loadedVideo === `${movie._id}_${round}` && (
                  <video
                    src={movie.videofile}
                    playsInline
                    muted
                    autoPlay
                    loop
                    className={`slider-vid`}
                    width="100%"
                    height="100%"
                    onClick={() => {
                      window.history.pushState({}, "", `${url}?video=${movie._id}_${round}`)
                      setOpen(true);
                      setCurrentItem(movie);
                      loadVideo(null);
                      clearTimeout(setVideoTimeout);
                    }}
                    onMouseOut = {event => {
                      loadVideo(null);
                      clearTimeout(setVideoTimeout);
                    }}
                  >
                  </video>
                )}
              </div>
            ))}
          </div>
      </div>
      )

      
      )}
      <Lightbox
        type={pageType}
        open={open}
        setOpen={setModal}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
      />
    </div>
  )
}
